import type { ContractTransactionResponse } from 'ethers'

/**
 * @description Wait for the on-chain transaction to complete, save the transaction hash or approve hash
 * @param { string } hash Transaction hash
 */
export const useWaitTx = (
  tx: ContractTransactionResponse,
  type: 'tx' | 'approve' = 'tx',
) => {
  const { txHash, approveHash, setBalance } = toRefs(useWalletStore())
  const isTxPending = computed(() => {
    return txHash.value !== null
  })
  const isApprovePending = computed(() => {
    return approveHash.value !== null
  })
  const waitTx = () => {
    const hashRef = type === 'tx' ? txHash : approveHash
    hashRef.value = tx.hash

    return tx.wait().then((resp) => {
      hashRef.value = null
      if (type === 'tx') {
        setBalance.value()
      }
      return resp
    })
  }
  return {
    waitTx,
    isTxPending,
    isApprovePending,
  }
}
