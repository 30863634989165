import { ZeroAddress, isAddress as isValidAddress, isHexString } from 'ethers'

// NOTE: there is currently no way to detect whether address exists on the current network

/**
 * @description verify ether address
 * @export
 * @return {*}  {({
 *   isZeroAddress: (addr: MaybeRef<string >) => boolean
 *   isAddress: (addr: MaybeRef<string >) => boolean
 * })}
 */
export function useVerifyAddress(): {
  isZeroAddress: (addr: MaybeRef<string>) => boolean
  isAddress: (addr: MaybeRef<string>) => boolean
  isTxHashString: (addr: MaybeRef<string>) => boolean
  checkTxExistence: (addr: MaybeRef<string>) => Promise<boolean>
}
export function useVerifyAddress(address: MaybeRef<string>): {
  isZeroAddress: (addr?: MaybeRef<string>) => boolean
  isAddress: (addr?: MaybeRef<string>) => boolean
  isTxHashString: (addr?: MaybeRef<string>) => boolean
  checkTxExistence: (addr: MaybeRef<string>) => Promise<boolean>
}
export function useVerifyAddress(address?: MaybeRef<string>) {
  const isZeroAddress = (addr = address as MaybeRef<string>) => {
    return ZeroAddress === String(unref(addr))
  }
  const isAddress = (addr = address as MaybeRef<string>) => {
    return isValidAddress(String(unref(addr)))
  }

  /**
   * @description verify transaction hash
   */
  const isTxHashString = (addr = address as MaybeRef<string>) => {
    const hashWithoutPrefix = String(toValue(addr))
    return isHexString(hashWithoutPrefix, 32)
  }
  const checkTxExistence = async (addr = address as MaybeRef<string>) => {
    const { defaultProvider } = useChains()
    if (defaultProvider.value === null || !isTxHashString(addr)) return false
    try {
      const transaction = await defaultProvider.value.getTransaction(
        toValue(addr),
      )
      return transaction !== null
    } catch (error) {
      console.error(error)
      return false
    }
  }
  return {
    isZeroAddress,
    isAddress,
    isTxHashString,
    checkTxExistence,
  }
}
